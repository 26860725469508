import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Mint } from "../pages/Mint";
import { Home } from "../pages/Home";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={() => <Home/>} />
        <Route path="/mint" exact component={() => <Mint/>} />
      </Switch>
    </Router>
  );
};

export default Routes;
