import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface CountProps {
  onDead: (status: boolean) => void;
}

export const Count: React.FC<CountProps> = ({ onDead }) => {
  const [date, setDate] = useState<any>({ d: 0, h: 0, m: 0, s: 0 });
  let timer: any = null;
  useEffect(() => {
    timer = setInterval(compute, 1000);
  }, []);
  const compute = () => {
    const t1 = "2022-04-06T08:00:00.000Z";
    let endDate = new Date(t1).getTime();
    
    // let endDate = new Date(Date.UTC(2022, 2, 27, 10, 0, 0)).getUTCDate();y
    // let startDate = new Date().getTime();
    let startDate = new Date().getTime();
    if (endDate - startDate <= 0) {
      setDate({
        d: 0,
        h: 0,
        m: 0,
        s: 0
      });
      clearInterval(timer);
      onDead(true);
    } else {
      var d = cd(t1, new Date(), "d");
      var h = cd(t1, new Date(), "h");
      var m = cd(t1, new Date(), "m");
      var s = cd(t1, new Date(), "s");
      onDead(false);
      setDate({
        d,
        h,
        m,
        s
      });
    }
  };
  return (
    <Container>
      <div className="count-item">
        <div className="num">{date.d}</div>
        <div className="unit">DAYS</div>
      </div>
      <div className="count-item">
        <div className="num">{date.h}</div>
        <div className="unit">HRS</div>
      </div>
      <div className="count-item">
        <div className="num">{date.m}</div>
        <div className="unit">MINS</div>
      </div>
      <div className="count-item">
        <div className="num">{date.s}</div>
        <div className="unit">SECS</div>
      </div>
    </Container>
  );
};

/*
2017-01-04 by xw获取倒计时 返回值数字
t1 开始时 时间格式
t2 结束时 时间格式
tg 要获取的值 字符串
  d 天
  h 时
  m 分
  s 秒
*/
function cd(t1, t2, tg) {
  let end: any = new Date(t1).toISOString();
  let start: any = new Date(t2).toISOString();
  //相差的毫秒数
  var ms = Date.parse(end) - Date.parse(start);
  var minutes = 1000 * 60;
  var hours = minutes * 60;
  var days = hours * 24;
  var years = days * 365;
  //求出天数
  var d = Math.floor(ms / days);
  //求出除开天数，剩余的毫秒数
  ms %= days;
  var h = Math.floor(ms / hours);
  ms %= hours;
  var m = Math.floor(ms / minutes);
  ms %= minutes;
  var s = Math.floor(ms / 1000);
  //返回所需值并退出函数
  switch (tg) {
    case "d":
      return d;
    case "h":
      return h;
    case "m":
      return m;
    case "s":
      return s;
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .count-item {
    background: #ffa3d7;
    border-radius: 4px;
    margin: 0 5px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 10px 0;
  }
  .num {
    font-size: 50px;
    margin-bottom: 10px;
    font-family: "MADETommySoftMedium";
  }
  .unit {
    font-weight: bold;
    font-size: 20px;
  }
  @media ${props => props.theme.device.sm} {
    .num {
      font-size: 40px;
    }
    .unit {
      font-size: 16px;
    }
  }
`;
