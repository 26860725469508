import React from "react";
import styled from "styled-components";

interface ShareProps {
  isDark?:boolean
}

export const Share: React.FC<ShareProps> = ({isDark}) => {
  return (
    <Container>
     
      <a href="https://twitter.com/mfmfgirls" target="blank">
      <img
        src={require("../../assets/imgs/menu_icon2_pink.png").default}
        alt=""
        className="icon"
      />
        </a>
      <a href="https://www.instagram.com/mofumofugirls/" target="blank">
      <img
        src={require("../../assets/imgs/menu_icon0_pink.png").default}
        alt=""
        className="icon"
      />
        </a>
      <a href="https://discord.com/invite/bJZ2VPPYwK" target="blank">
      <img
        src={require("../../assets/imgs/menu_icon3_pink.png").default}
        alt=""
        className="icon"
      />
      </a>
      <a href="https://opensea.io/collection/mofu-mofu-girls-nft" target="blank">
      <img
        src={require("../../assets/imgs/menu_icon1_pink.png").default}
        alt=""
        className="icon"
      />
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left:20px;
  .icon{
    width:30px;
    margin:0 5px;
    transition:0.3s ease all;
    cursor:pointer;
    &:hover{
      transform:scale(1.2);
    }
  }
  @media ${(props) => props.theme.device.sm} {
    margin-left:10px;
    .icon{
      margin:0 3px;
      width:20px;
    }
  }
`;
