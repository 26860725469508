import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import teamBg from "../assets/imgs/team_bg.jpg";
import tigerBg from "../assets/imgs/about_tiger_bg.png";
import { Share } from "./components/Share";
import { Count } from "./components/Count";
import { useHistory } from "react-router-dom";
import video from "../assets/imgs/mofu.mp4";
import Modal from "react-modal";

interface BasicProps {}

export const Home: React.FC<BasicProps> = () => {
  const [off, setOff] = useState(false);
  const [notStartVisible, setNotStartVisible] = useState(false);
  const [deadline, setDeadline] = useState<boolean>(false);
  let history = useHistory();
  useLayoutEffect(() => {
    window.addEventListener("scroll", event => {
      setOff(!!window.scrollY);
    });
  });

  function showNotStartModal() {
    setNotStartVisible(true);
    setTimeout(() => setNotStartVisible(false), 2000);
  }
  return (
    <Container>
      <div className="header-wrap"></div>
      <Header
        style={{
          height: off ? "40px" : "100px",
          backgroundColor: off ? "rgb(255, 255, 255,0.6)" : "rgb(255, 255, 255)"
        }}
      >
        <a href="/">
          {" "}
          <img
            src={require("../assets/imgs/logo_pink.png").default}
            alt=""
            className="logo"
          />
        </a>
        <Menu>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#mint">Mint</a>
          </li>
          <li>
            <a href="#roadmap">Roadmap</a>
          </li>
          <li>
            <a href="#team">Team</a>
          </li>
          <Share />
        </Menu>
      </Header>
      <Banner>
      <img
              src={require("../assets/imgs/banner.png").default}
              alt=""
              width="100%"
          height="auto"
            />
      
      </Banner>
      <About id="about">
        <h1>Love, Peace & Animals</h1>
        <Button>
          <img src={require("../assets/imgs/menu_icon3.png").default} alt="" />
          <a target="_blank" href="https://discord.com/invite/bJZ2VPPYwK">
            Join our Discord
          </a>
        </Button>
        <div className="content">
          <div className="brief">
            <p className="title">About Mofu Mofu Girls NFT</p>
            <p>
              Mofu Mofu Girls is a collection of 8,888 randomly generated unique NFT on the Ethereum blockchain. Mofu Mofu means fluffy and cuddly in Japanese. Each MFMF Girl is an avatar of a fluffy and cute animal who is seeking adoption from animal lovers. This collective art series is inspired by Japanese popular culture "Girls anime/Shojo anime(少女アニメ) " and "Kemo-mimi(獣耳/けもみみ)".
              <br />
              <br />
              As one of the most popular Japanese cultures, "Shojo Anime" has
              had a rich history in Japan since the 1960s. "Kemo-mimi" (first
              depicted in Kabuki play in the Edo period) is also one of the most
              well-known "kawaii/かわいい" iconic expressions, which is deeply
              rooted in Japanese culture and used frequently and repeatedly in
              Japanese anime and manga works.
            </p>
          </div>
          <div className="image" id="mint">
            <img
              src={require("../assets/imgs/about_pic.png").default}
              alt=""
              className="about-pic"
            />
          </div>
        </div>

        <Count onDead={status => setDeadline(status)} />
        <div
          className={`mint-btn ${deadline ? "" : "disabled"}`}
          onClick={() =>
            deadline ? history.push("/mint") : showNotStartModal()
          }
        >
          Mint Now
        </div>
        <Modal
          isOpen={notStartVisible}
          className="modal forbid"
          contentLabel="Example Modal"
        >
          <h3>
            Minting has not started yet. <br />
            Please come back later.
          </h3>
        </Modal>
      </About>
      <Roadmap id="roadmap">
        <img
          src={require("../assets/imgs/about_tiger_bg.png").default}
          alt=""
          className="tiger-bg"
        />
        <h2>
          <img
            src={require("../assets/imgs/maproad_claw1.png").default}
            alt=""
            className="claw-left"
          />
          <span>Roadmap</span>
          <img
            src={require("../assets/imgs/maproad_claw2.png").default}
            alt=""
            className="claw-right"
          />
        </h2>
        <div className="content">
          <div className="left">
            <div className="step-item">
              <div className="divider"></div>
              <div className="step-title l1">
                <span>Launch</span>
                <span className="dot"></span>
              </div>
              <div className="step-text">
                Quality is the key. We are aiming to make our genesis generation
                outstanding and bring MFMF holders kicky and high-quality
                artworks.
              </div>
            </div>
            <div className="step-item">
              <div className="divider"></div>
              <div className="step-title l2">
                <span>30%</span>
                <span className="dot"></span>
              </div>
              <div className="step-text">
                5 MFMF Girls NFT will be airdropped to 5 randomly selected
                winners from the first 30% holders.
              </div>
            </div>
            <div className="step-item">
              <div className="divider"></div>
              <div className="step-title l3">
                <span>80%</span>
                <span className="dot"></span>
              </div>
              <div className="step-text">
                10 MFMF Girls NFT and some goodies will be airdropped to 10
                randomly selected winners with 5 or more MFMF Girls NFT in their
                wallets.
              </div>
            </div>
            <div className="step-item">
              <div className="step-title l4">
                <span>Next Generation</span>
                <span className="dot"></span>
              </div>
              <div className="step-text">
                After presenting our first-generation Mofu Mofu Girls NFT, we
                want to explore more concepts in order to present a distinct
                second generation. The goal is to increase MFMF Girls’
                popularity and bring long-term benefits for MFMF holders.
              </div>
            </div>
          </div>
          <div className="right">
            <div className="step-item">
              <div className="step-title r1">
                <span className="dot l"></span>
                <span>Pre-sale</span>
              </div>
              <div className="step-text">
                600 spots of Pre-sale Whitelist will be given to our early birds
                and active members.
              </div>
            </div>
            <div className="step-item">
              <div className="step-title r2">
                <span className="dot l"></span>
                <span>50%</span>
              </div>
              <div className="step-text">
                First Donation - the team will set aside 10% of the profits to
                be donated to the World Animal Awareness Society.
              </div>
            </div>
            <div className="step-item">
              <div className="step-title r3">
                <span className="dot l"></span>
                <span>SOLD OUT</span>
              </div>
              <div className="step-text">
                MFMF DAO members will have voting rights for community
                activities and future decisions. Second Donation - 10% of the
                second-half profits will be donated to animal saving
                associations and projects.
              </div>
            </div>
          </div>
        </div>
      </Roadmap>
      <Donation id="donation">
        <div className="content">
          <div className="title">
            <span>Donation</span>
            <img
              src={require("../assets/imgs/donation_heart.png").default}
              alt=""
              className="donation-heart"
            />
          </div>
          <p>
            This project is aiming to raise awareness of animal welfare and
            rescue & show our respect to people who are working and volunteering
            in this field. 10% of the profit will be donated to the
            <b> World Animal Awareness Society</b>. By adopting/sharing this
            unique NFT project, you will be helping the abandoned
            animals find their shelters and assisting to bring attention to the
            poor animals.
          </p>
        </div>
        <img
          src={require("../assets/imgs/donation_pic.png").default}
          alt=""
          className="donation-pic"
        />
      </Donation>
      <Team id="team">
        <img
          src={require("../assets/imgs/team_bg.jpg").default}
          alt=""
          className="team-bg"
        />{" "}
        <h2>Team</h2>
        <div className="content">
          <TeamMember>
            <p className="name">Miko</p>
            <p className="post">Artist</p>
            <img
              src={require("../assets/imgs/team_member1.jpg").default}
              alt=""
              className="avatar"
            />
            <div className="desc">
              Hi all, I started Oil Painting at 7 years old. I’m passionate
              about Japanese cultural history and contemporary art studies, and
              always trying to explore the possibilities of new artistic
              expressions. I love girls' amine.
              <br />
              Favourite animal: Tiger
            </div>
          </TeamMember>
          <TeamMember>
            <p className="name">Yui</p>
            <p className="post">Marketing</p>
            <img
              src={require("../assets/imgs/team_member2.jpg").default}
              alt=""
              className="avatar"
            />
            <div className="desc">
              Hey guys, I have been in marketing for 10 years and specialised in
              promoting Japanese cultural productions. MFMF Girls combines my
              enthusiasm for Japanese culture and animal protection. I hope you
              guys love it.
              <br />
              Favourite animal: Deer
            </div>
          </TeamMember>
          <TeamMember>
            <p className="name">Neon</p>
            <p className="post">Tech Lead</p>
            <img
              src={require("../assets/imgs/team_member3.jpg").default}
              alt=""
              className="avatar"
            />
            <div className="desc">
              Used to work in one of the world's top Internet companies. 10+
              years of industry experience. Passionate about tech and gaming.
              <br />
              Favourite animal: Dog
            </div>
          </TeamMember>
          <TeamMember>
            <p className="name">Hikaru</p>
            <p className="post">Creative Director</p>
            <img
              src={require("../assets/imgs/team_member4.jpg").default}
              alt=""
              className="avatar"
            />
            <div className="desc">
              Experienced in the advertisement and marketing industry.
              Interested in modern pop art, pop culture, and Japanese
              traditional and modern culture.
              <br />
              Favourite animal: Wolf
            </div>
          </TeamMember>
          <TeamMember>
            <p className="name">Aoi</p>
            <p className="post">Front-end Dev</p>
            <img
              src={require("../assets/imgs/team_member5.jpg").default}
              alt=""
              className="avatar"
            />
            <div className="desc">
              Hi all, I have 3 years of work experience as a Dev. My passions
              are front-end development and animations. My love for animations
              is even deeper than the oceans.
              <br />
              Favourite animal: Cat
            </div>
          </TeamMember>
        </div>
      </Team>
      <Footer id="FAQ">
        <h2>FAQ</h2>
        <div className="question-container">
          <div className="question-wrap">
            <p className="question" style={{ display: "flex" }}>
              <span className="label">Q:</span>
              <span>Official links?</span>
            </p>
            <p className="question" style={{ display: "flex" }}>
              <span className="label">A:</span>
                <span>
                <a href="https://discord.gg/bJZ2VPPYwK" target="_blank">Discord - https://discord.gg/bJZ2VPPYwK</a>     
                <br />
                <a href="https://twitter.com/mfmfgirls" target="_blank">Twitter - https://twitter.com/mfmfgirls</a> 
              </span>
            </p>
          </div>
          <div className="question-wrap">
            <p className="question" style={{ display: "flex" }}>
              <span className="label">Q: </span>
              <span>What is the total supply?</span>
            </p>
            <p className="question">
              <span className="label">A: </span>
              <span>8,888</span>
            </p>
          </div>
          <div className="question-wrap">
            <p className="question" style={{ display: "flex" }}>
              <span className="label">Q: </span>
              <span>How much will it cost to mint a Mofu Mofu NFT?</span>
            </p>
            <p className="question" style={{ display: "flex" }}>
              <span className="label">A: </span>
              <span>Pre-sale Mint 0.05 ETH <br />
               Public Sale Stage One - 0.06 ETH  <br />
               Public Sale Stage Two - 0.08 ETH</span>
            </p>
          </div>
          <div className="question-wrap">
            <p className="question" style={{ display: "flex" }}>
              <span className="label">Q: </span>
              <span>When is the public-sale?</span>
            </p>
            <p className="question">
              <span className="label">A: </span>
              <span>6 APR 08:00 UTC</span>
            </p>
          </div>
        </div>
        <div className="footer">
          <img
            src={require("../assets/imgs/logo_pink.png").default}
            alt=""
            className="logo"
          />
          <div className="copyright">
            © 2022 MOFU MOFU GIRLS. All Rights Reserved.
          </div>
          <div className="share-wrap">
            <Share />
          </div>
        </div>
      </Footer>
    </Container>
  );
};
export const Container = styled.div`
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  background: #fff;
  @media ${props => props.theme.device.sm} {
    font-size: 13px;
    line-height: 16px;
  }
  .header-wrap {
    height: 100px;
    padding: 20px 40px;
    @media ${props => props.theme.device.sm} {
      height: 40px;
      padding: 10px;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  right: 0;
  width: calc(100% - 80px);
  transition: 0.3s ease height;
  height: 100px;
  box-sizing: content-box;
  padding: 20px 40px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: flex-end;
  .logo {
    position: absolute;
    left: 40px;
    top: 20px;
    height: calc(100% - 40px);
    flex-shrink: 0;
  }
  @media ${props => props.theme.device.sm} {
    height: 40px !important;
    padding: 10px;
    width: calc(100% - 20px);
    .logo {
      left: 20px;
      top: 10px;
      height: calc(100% - 20px);
    }
  }
`;

export const Menu = styled(({ isDark, ...rest }) => <ul {...rest} />)<{
  isDark: boolean;
}>`
  display: flex;
  color: ${props => (props.isDark ? "#ffa3d7" : "#ff67bc")};
  a {
    color: ${props => (props.isDark ? "#ffa3d7" : "#ff67bc")};
  }
  height: 40px;
  align-items: center;
  li {
    margin-left: 20px;
    font-weight: bold;
    font-size: 20px;
    transition: 0.3s ease all;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
  @media ${props => props.theme.device.sm} {
    font-size: 14px;
    margin-left: 10px;
    li {
      font-size: 12px;
      margin-left: 8px;
    }
  }
`;

const Banner = styled.div`
  background: #ffafd7;
  position: relative;
  .mofu {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 95%;
  }
  .banner-left {
    position: absolute;
    left: 0;
    height: 100%;
  }
  .banner-right {
    position: absolute;
    right: 0;
    height: 100%;
  }
  .banner-title {
    position: absolute;
    bottom: 20px;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const About = styled.div`
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  background:#fff;
  margin-top:-10px;
  /* background: url(${tigerBg}); */
  h2 {
    color: #ff84c6;
    white-space: nowrap;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  h1 {
    color: #ff84c6;
    white-space: nowrap;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  .content {
    display: flex;
    margin: 0 50px;
    @media ${props => props.theme.device.sm} {
      margin: 0 15px;
    }
    .brief {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
  color: #ff84c6;

    }
    .image {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
      }
    }
    .title {
      font-size: 30px;
      font-family: "MADETommySoftMedium";
      margin-bottom: 24px;
    }
  }
  .mint-btn {
    height: 54px;
    line-height: 54px;
    width: 340px;
    background: linear-gradient(to right, #ff65b4, #b961fe);
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    border-radius: 27px;
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
    &.disabled {
      background: #ffb1d9;
    }
  }
  @media ${props => props.theme.device.sm} {
    padding: 30px 0;
    margin-top:-3px;
    h2 {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .content {
      flex-direction: column;
      .title {
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
      }
      .image {
        margin-top: 10px;
      }
    }
    .mint-btn {
      height: 36px;
      line-height: 36px;
      width: 240px;
      font-size: 20px;
    }
  }
`;

const Button = styled.div`
  display: flex;
  height: 48px;
  width: 300px;
  border-radius: 24px;
  background: #9b98ff;
  justify-content: center;
  align-items: center;
  font-family: "MADETommySoftMedium";
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 40px;
  cursor: pointer;
  img {
    width: 50px;
    margin-right: 10px;
  }
  @media ${props => props.theme.device.sm} {
    height: 36px;
    line-height: 36px;
    width: 240px;
    font-size: 20px;
    img {
      width: 30px;
    }
  }
`;

const Roadmap = styled.div`
position: relative;
/* background-color: #ff68a9; */
/* background: url(${tigerBg}); */
padding: 50px 0;
.tiger-bg {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
h2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 40px;
  z-index: 100;
  }
  .claw-left {
    width: 40px;
    margin-right: 10px;
  }
  .claw-right {
    width: 34px;
    margin-top: 20px;
    margin-left: 10px;
  }
  .content {
    display: flex;
    padding: 0 50px;
    .left {
      margin-right: 20px;
    }
    .right {
      margin-left: 30px;
    }
    .left,
    .right {
      flex: 1;
    }
    .step-item {
      position: relative;
      .divider {
        position: absolute;
        top: 15px;
        bottom: -120px;
        right: -21px;
        border-right: 2px solid #fff;
      }
    }
    .step-title {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: bold;
      position: relative;
      .dot {
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        top: 15px;
        right: -23px;
        border-radius: 3px;
        background: #fff;
        &.l {
          right: auto;
          left: -33px;
        }
      }
      &.l2 {
        margin-top: 30px;
      }
      &.l3 {
        margin-top: 60px;
      }
      &.l4 {
        margin-top: 100px;
      }
      &.r1 {
        margin-top: 100px;
      }
      &.r2 {
        margin-top: 50px;
      }
      &.r3 {
        margin-top: 60px;
      }
    }
  }
  @media ${props => props.theme.device.sm} {
    padding: 30px 0;
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .claw-left {
      width: 30px;
    }
    .claw-right {
      width: 24px;
    }
    .content {
      padding: 0 15px;
      .right {
        margin-left: 20px;
      }
      .step-item {
        .divider {
          bottom: -11a0px;
        }
      }
      .step-title {
        font-size: 18px;
        .dot {
          top: 8px;
          &.l {
            left: -23px;
          }
        }
      }
    }
  }
`;

const Donation = styled.div`
  padding: 50px 0;
  margin: 0 50px;
  display: flex;
  color: #ff68a9;
  .content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-right: 10px;
  }
  .title {
    text-align: center;
    font-size: 30px;
    font-family: "MADETommySoftMedium";
    margin-bottom: 30px;
  }
  .donation-heart {
    width: 32px;
  }
  .donation-pic {
    width: 50%;
    align-self: center;
    border-radius: 10px;
  }
  @media ${props => props.theme.device.sm} {
    padding: 20px 0;
    margin: 0 15px;
    .title {
      font-size: 24px;
    }
    .donation-heart {
      width: 24px;
    }
  }
`;
const Team = styled.div`
/* background: #b9bde4 url(${teamBg}); */
/* background-size: 100% 100%; */
padding: 50px 0;
text-align: center;
position:relative;
.team-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index:0;
  object-fit:cover;
}
h2 {
  position:relative;
  font-size: 40px;
  margin-bottom: 40px;
}
.content {
  position:relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media ${props => props.theme.device.sm} {
  padding: 30px 0;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
`;
const TeamMember = styled.div`
  width: 24%;

  margin: 0 3%;
  margin-bottom: 20px;
  .name {
    font-size: 30px;
    font-family: "MADETommySoftMedium";
    margin-bottom: 10px;
  }
  .post {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .avatar {
    width: 90%;
    border-radius: 50%;
  }
  .desc {
    text-align: left;
  }
  @media ${props => props.theme.device.sm} {
    width: 42%;
    margin: 0 2%;
    margin-bottom: 14px;
    .name {
      font-size: 18px;
    }
    .post {
      font-size: 14px;
    }
  }
`;
export const Footer = styled.div`
  position: relative;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ff3eaa;
  h2 {
    text-align: center;
    font-size: 40px;
  }
  .question-container {
    text-align: left;
  }
  .question-wrap {
    margin-top: 30px;
  }
  .question a{
    color: #ff3eaa;
  }
  .question {
    font-size: 24px;
  }
  .label {
    margin-right: 20px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #ffc6e6;
    margin: 0 20px;
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 10px;
    align-self: stretch;
  }
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  .share-wrap {
    position: absolute;
    bottom: 120px;
    right: 40px;
  }
  @media ${props => props.theme.device.sm} {
    .footer.mint {
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      height: 120px;
    }
    padding-top: 30px;
    h2 {
      font-size: 24px;
    }
    .question {
      font-size: 14px;
      margin-top: 20px;
    }
    .label {
      margin-right: 10px;
    }
    .share-wrap {
      right: 15px;
    }
  }
`;
