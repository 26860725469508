import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Share } from "./components/Share";
import { Container, Footer, Header, Menu } from "./Home";
import Web3 from "web3";
import { Contract, ContractOptions } from "web3-eth-contract";
import { AbiItem } from "web3-utils";
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnect from "@walletconnect/client";
import Modal from "react-modal";
import modalBg from "../assets/imgs/modal_bg.jpg";
import { useHistory } from "react-router-dom";
declare var window: any;
interface MintProps {}

export const Mint: React.FC<MintProps> = ({}) => {
  const isPreSale = false; // presale期间： isPreSale = true，isPublicMint = false
  const isPublicMint = true; //presale结束： presale = false， isPublicMint= true
  const price = 0.06; // 价格
  const gasLimit = "300000";
  const endpoint =
       "https://mainnet.infura.io/v3/5edafd63e8b5464c99456e644b256f9c";
      //  "https://eth-rinkeby.alchemyapi.io/v2/Btn2gsoxVt7oMeNYmzb_JG1-JR4L7ABm";
  const CONTRACT_ADDRESS = "0xdcbabf26cce3234b96ade42c506ea2acf46d3906";

  const [visible, setVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [notStartVisible, setNotStartVisible] = useState(false);

  const bridge = "https://bridge.walletconnect.org";
  const [ownerAddress, setOwnerAddress] = useState("");

  const [connector, setConnector] = useState(
    new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
  );
  const [connected, setConnected] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [count, setCount] = useState(1);
  const [nftNumber, setNFTNumber] = useState(0);
  const [amount, setAmount] = useState(price);
  let history = useHistory();
  const [off, setOff] = useState(false);
  const ABI = [
    {
      inputs: [
        {
          internalType: "uint256",
          name: "numberOfTokens",
          type: "uint256"
        }
      ],
      name: "mint",
      outputs: [],
      stateMutability: "payable",
      type: "function"
    },
    {
      inputs: [
        {
          internalType: "uint8",
          name: "numberOfTokens",
          type: "uint8"
        }
      ],
      name: "mintPreSaleList",
      outputs: [],
      stateMutability: "payable",
      type: "function"
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256"
        }
      ],
      stateMutability: "view",
      type: "function"
    }
  ];
  useLayoutEffect(() => {
    window.addEventListener("scroll", event => {
      setOff(!!window.scrollY);
    });
  });

  useEffect(() => {
    const t1 = "2022-04-06T08:00:00.000Z"; // mint页面计时器
    let endDate = new Date(t1).getTime();
    let startDate = new Date().getTime();
    if (endDate - startDate > 0) {
      setNotStartVisible(true);
      setTimeout(() => history.push("/"), 2000);
    }
  }, []);

  function handleChange(e) {
    setCount(e.target.value);
    setAmount(Number(parseFloat((price * e.target.value).toString()).toFixed(2)));
    // setAmount(price * e.target.value);
  }

  async function getTx(web3: Web3, nonce: string, gas: string, value: string) {
    const nftContract = new web3.eth.Contract(
      ABI as AbiItem[],
      CONTRACT_ADDRESS,
       { gas: 300000 } as ContractOptions
    );
   
    const tx = {
      // this could be provider.addresses[0] if it exists
      from: ownerAddress,
      // target address, this could be a smart contract address
      to: CONTRACT_ADDRESS,
      // optional if you want to specify the gas limit
      //  gas: gas,
      //  maxFeePerGas: web3.utils.toWei('2','gwei'),
      // maxPriorityFeePerGas: web3.utils.toWei('1','gwei'),
      value: value,
      // nonce: nonce,
      // optional if you are invoking say a payable function
      // value: value,
      // this encodes the ABI of the method and the arguements
      data: nftContract.methods.mintPreSaleList(count).encodeABI()
    };

    if (isPublicMint) {
      tx.data = nftContract.methods.mint(count).encodeABI();
    }

    return tx;
  }

  async function mintNFT() {
    if (isMetamask) {
      await mintNFTByMetaMask();
    } else {
      await mintNFTByConnector();
    }
  }

  async function mintNFTByConnector() {
    let web3 = new Web3(new Web3.providers.HttpProvider(endpoint));

    try {
      const value = web3.utils.toWei(amount.toString(), "ether");
      const nonce = await web3.eth.getTransactionCount(ownerAddress, "latest"); //get latest nonce
      const tx = await getTx(web3, nonce.toString(), gasLimit, value);

      connector
        .sendTransaction(tx)
        .then(result => {
          const nftContract = new web3.eth.Contract(
            ABI as AbiItem[],
            CONTRACT_ADDRESS,
            { gas: 300000 } as ContractOptions
          );
          return nftContract.methods
            .totalSupply()
            .call({ from: ownerAddress }, function(error, result) {});
        })
        .then(result => {
          setNFTNumber(result);
          setSuccessVisible(true); //
          console.log(result);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function mintNFTByMetaMask() {
   
    let web3 = new Web3(window.ethereum);

    try {
      const value = web3.utils.toWei(amount.toString(), "ether");
      

      const nonce = await web3.eth.getTransactionCount(ownerAddress, "latest"); //get latest nonce
    
     
      const tx = await getTx(
        web3,
        web3.utils.numberToHex(nonce),
        //  web3.utils.numberToHex(gasafee),
         web3.utils.numberToHex(gasLimit),
        web3.utils.numberToHex(value)
      );

      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [tx]
        })
        .then(result => {
          const nftContract = new web3.eth.Contract(
            ABI as AbiItem[],
            CONTRACT_ADDRESS,
            { gas: 3000000 } as ContractOptions
          );
          return nftContract.methods
            .totalSupply()
            .call({ from: ownerAddress }, function(error, result) {});
        })
        .then(result => {
          setNFTNumber(result);
          setSuccessVisible(true); //
          console.log(result);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function showWalletOptionsModal(e) {
    setVisible(true);
    return;
  }

  async function connectViaMetaMask(e) {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      const account = accounts[0];
      setOwnerAddress(accounts[0]);
      setConnected(true);
      setVisible(false); // 关闭钱包选择窗口
      setIsMetamask(true);
    } else {
      console.error("MetaMask not installed");
    }
  }

  async function connectViaWalletConnect(e) {
    e.preventDefault();
    // bridge url

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    setConnector(connector);

    if (!connector) {
      return;
    }

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    } else {
      console.log("already connected");
      const { chainId, accounts } = connector;
      setOwnerAddress(accounts[0]);
      setConnected(true);
      setVisible(false); // 关闭钱包选择窗口
      setIsMetamask(false);
    }

    connector.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }

      const { chainId, accounts } = payload.params[0];
      setOwnerAddress(accounts);
    });

    connector.on("connect", (error, payload) => {
      console.log(`connector.on("connect")`);
      const { chainId, accounts } = payload.params[0];

      setOwnerAddress(accounts[0]);
      setConnected(true);
      setVisible(false); // 关闭钱包选择窗口
      if (error) {
        throw error;
      }
    });

    connector.on("disconnect", (error, payload) => {
      console.log(`connector.on("disconnect")`);
      setConnected(false);
      // setConnector(null);
      setOwnerAddress("");
      if (error) {
        throw error;
      }
    });
  }

  return (
    <Container style={{ background: "#fff" }}>
      <div className="header-wrap"></div>
      <Header
        style={{
          height: off ? "40px" : "100px",
          backgroundColor: off ? "rgb(255, 255, 255,0.6)" : "rgb(255, 255, 255)"
        }}
      >
        <a href="/">
          <img
            src={require("../assets/imgs/logo_pink.png").default}
            alt=""
            className="logo"
          />
        </a>
        <Menu isDark={true}>
          <li>
            <a href="/#about">About</a>
          </li>
          <li>
            <a href="/#roadmap">Roadmap</a>
          </li>
          <li>
            <a href="/#team">Team</a>
          </li>
          <li>
            <a href="/#FAQ">FAQ</a>
          </li>
          <Share isDark={true} />
        </Menu>
      </Header>
      <Connnect>
        <img
          src={require("../assets/imgs/mofu.gif").default}
          alt=""
          className="bg"
        />
        <div className="connect-wrap">
          <div className="select-wrap">
            <span className="num">{price}</span>
            <span className="unit">ETH</span>
            <select name="carlist" onChange={handleChange}>
              {/* <option value="" selected  hidden >Select</option> */}
              <option className="select-option" value="1">
                1
              </option>
              <option className="select-option" value="2">
                2
              </option>
              <option className="select-option" value="3">
                3
              </option>
              <option className="select-option" value="4">
                4
              </option>
              <option className="select-option" value="5">
                5
              </option>
              <option className="select-option" value="6">
                6
              </option>
              <option className="select-option" value="7">
                7
              </option>
              <option className="select-option" value="8">
                8
              </option>
              <option className="select-option" value="9">
                9
              </option>
              <option className="select-option" value="10">
                10
              </option>
            </select>
          </div>
          <div className="divider"></div>
          <div className="total-wrap">
            <span className="label">Total:</span>
            <span className="num">{amount}</span>
            <span className="unit">ETH</span>
          </div>
          {!connected && (
            <div className="connect-btn" onClick={showWalletOptionsModal}>
              Connect Wallet
            </div>
          )}
          {connected && isPreSale && (
            <div className="connect-btn" onClick={mintNFT}>
              Presale Mint
            </div>
          )}

          {connected && isPublicMint && (
            <div className="connect-btn" onClick={mintNFT}>
              Mint Now
            </div>
          )}
          {/* <div onClick={() => setSuccessVisible(true)} style={{ color: "red",textAlign:"center",cursor:"pointer" }}>
            Mint succeed
          </div> */}
        </div>
      </Connnect>

      <Modal
        isOpen={visible}
        className="modal"
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
      >
        <img
          src={require("../assets/imgs/close.svg").default}
          alt=""
          className="close"
          onClick={() => setVisible(false)}
        />
        <h3>Connect Wallet</h3>
        <div className="tips">
          Please connect your wallet with Ethereum Chain
        </div>
        <div className="pay-item" onClick={connectViaMetaMask}>
          <img
            src={require("../assets/imgs/Metamask.svg").default}
            alt=""
            className="pay-logo"
          />
          <div className="pay-name">Metamask</div>
        </div>
        <div className="pay-item" onClick={connectViaWalletConnect}>
          <img
            src={require("../assets/imgs/WalletConnect.svg").default}
            alt=""
            className="pay-logo"
          />
          <div className="pay-name">WalletConnect</div>
        </div>
      </Modal>
      <Modal
        isOpen={successVisible}
        className="modal"
        contentLabel="Example Modal"
      >
        <img
          src={require("../assets/imgs/close.svg").default}
          alt=""
          className="close"
          onClick={() => setSuccessVisible(false)}
        />
        <h3>Minting Succeed</h3>
        <img
          className="modal-bg"
          src={require("../assets/imgs/succeed_modal_bg.png").default}
          alt=""
        />
        <h3>Mofu Mofu Girls #{nftNumber}</h3>
        <div
          className="connect-btn"
          style={{ marginTop: "20px", marginBottom: "20px" }}
          onClick={() => setSuccessVisible(false)}
        >
          Continue Minting
        </div>
      </Modal>
      <Modal
        isOpen={notStartVisible}
        className="modal forbid"
        contentLabel="Example Modal"
      >
        <h3>
          Minting has not started yet. <br />
          Please come back later.
        </h3>
      </Modal>
      <Footer id="FAQ">
        <div className="footer mint" style={{ color: "#fe4daf" }}>
          <img
            src={require("../assets/imgs/logo_pink.png").default}
            alt=""
            className="logo"
          />
          <div className="copyright">
            © 2022 MOFU MOFU GIRLS. All Rights Reserved.
          </div>
        </div>
      </Footer>
    </Container>
  );
};

const Connnect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  .bg {
    width: 40%;
  }
  .connect-wrap {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-right: 40px;
    color: #fe4daf;
    .num {
      font-size: 60px;
      font-family: "MADETommySoftMedium";
    }
  }
  select {
    border-color: #fff;
    background: #fe4daf;
    border-radius: 6px;
    width: 100px;
    text-align: center;
    text-align-last: center;
    font-size: 16px;
    color: #fff;
    padding-right: 14px;
    padding-left: 14px;
    appearance: none;
    .select-option {
      text-align: center;
    }
  }
  .divider {
    height: 1px;
    background: #ffc6e6;
  }
  .select-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .unit {
      margin-right: auto;
    }
  }
  .total-wrap {
    margin-top: 30px;
    display: flex;
    .label {
      flex: 1;
      font-size: 26px;
      font-weight: bold;
    }
  }
  .unit {
    font-size: 26px;
    font-weight: bold;
    margin-left: 5px;
    line-height: 50px;
  }
  .connect-btn {
    height: 54px;
    line-height: 54px;
    width: 340px;
    color: #fff;
    background: linear-gradient(to right, #fc66b8, #b961fe);
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    border-radius: 27px;
    margin: 0 auto;
    margin-top: 30px;
  }
  @media ${props => props.theme.device.sm} {
    flex-direction: column;
    .bg {
      width: 50%;
      margin-bottom: 10px;
    }
    .connect-wrap {
      /* margin-left: -40px; */
      margin-top: 20px;
      padding-right: 0;
      width: 50%;
      .num {
        font-size: 30px;
      }
      select {
        width: 60px;
        text-align: center;
      }
    }
    .select-wrap {
      margin-bottom: 20px;
    }
    .unit {
      font-size: 16px;
      line-height: 24px;
    }
    .connect-btn {
      height: 36px;
      line-height: 36px;
      width: 200px;
      font-size: 18px;
    }
    .total-wrap {
      margin-top: 10px;
      .label {
        font-size: 16px;
      }
    }
  }
`;
