/** A cool guy. */
const device = {
  xs: `(max-width:576px)`,
  sm: `(max-width:768px)`,
  md: `(max-width:992px)`,
  lg: `(max-width:1200px)`,
  xl: `(max-width:1600px)`,
  xxl: `(min-width: 1600px)`,
};
export const theme = {
  primary: "#1890ff",
  gray: "#d9d9d9",
  warning: "#faad14",
  /** A cool guy. */
  device
}
