import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";

import Routes from "./routes";
import "./index.less";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/style/theme";

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
